@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Khand:wght@300;400;500;600;700&family=Rajdhani:wght@300;500;700&family=Varela+Round&display=swap');

*:lang(he) {
  font-family: 'Assistant' !important;
}

*:lang(en) {
  font-family: 'Khand' !important;
}

html,
body {
  overflow-y: hidden;
    overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}
/* body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0px;
} */
#root {
  overflow-y: hidden;
  overflow-x: hidden;
}
header {
  background-color: black !important;
  z-index: 9999 !important;
  width: 100% !important;
}
header > div {
  width: 100% !important;
  justify-content: space-between !important;
}

.formSection > .headline {
  background-color: #F8C937;
  border-radius: 5px 5px 0px 0px;
}
.formSection > .headline > h3 {
  padding: 10px;
}
.formSection {
  border-radius: 5px;
  background-color: rgba(239, 239, 239, 0.836);
  /* height: 100vh; */
}

/* nav > div > div {
  top: 4rem !important;
} */

.english {
    font-family: 'Rajdhani', sans-serif !important;
}

.hebrew {
    font-family: 'Assistant', sans-serif !important;
}

.blackBg {
    background-color: black !important;
}

/* .custom-file-upload:hover {
    color: #262626 !important;
    background-color: white !important;
    border-color: #262626;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 2px solid;
    display: inline-block;
    padding: 11.5px;
    cursor: pointer;
    text-align: center;
  }

  .thumbnailHolder {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    border-radius: 10px;
    background-position: center !important;
      border: 2px solid;
  } */


  .flexForm > div {
    margin: 2px !important;
  }
  .pointer:hover {
    cursor: pointer;
  }


  .tasksTable > table {
    border-collapse: collapse;
    width: 100%;
    border: 2px solid;
    border-radius: 10px !important;
  }

  .tasksTable > table > tbody > tr > td, .tasksTable > table > tr > th {
    border: 1px solid #dddddd;
    /* text-align: right !important; */
    padding: 12px !important;
  }


  .tasksTable > table > tbody > tr:nth-child(even) {
    background-color: #dddddd;
  }
  .error {
    color: red
  }
  .capitalize {
    text-transform: capitalize;
  }

.dateTimePicker {
  width: 100%;
}
.dateTimePicker > div {
  padding: 5px;
  border-radius: 20px;
}
  @media screen and (max-width: 768px) {
    .font20, .font20 > div > input {
      font-size: 20px !important
    }
    .inPageHeader {
      padding: 3rem 2rem 2rem 2rem;
    }
    header > div {
      justify-content: space-around !important;
    }
    header {
      padding: 10px !important
    }
    header > div > button {
      position: absolute !important;
      left: 10px !important
    }
    /* .logoImgDiv {
      position: absolute !important;
      right: 10px;
    } */
    .MuiDrawer-paperAnchorLeft {
      padding-top: 1rem !important
    }
    .gridItem {
      min-height: 10rem !important;
    }
    .item-container {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border: 1px solid black;
      padding: 5px;
      margin: 5px;
      border-radius: 10px;
      font-size: 12px
    }
    .inpageSection {
      padding: 10px;
    }
    .multi-select {
      width: 300px !important
    }
    .iconBox {
      display: block;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      margin: 5px;
      width: 30%;
    }
    .iconBox > h2 {
      margin: 0px;
      font-size: 14px
    }
    .pivoted > svg {
    height: 30px;
    width: 30px
    }
    .MuiTabs-fixed > div > button {
      font-size: 12px !important;
      padding: 2px 4px !important;
      text-transform: capitalize;
    }
    #gameLogo {
      /* width: 50px; */
      border-radius: 5px;
    }

    .flip-card {
      background-color: transparent;
      height: 160px;
      /* width: 300px;
      border: 1px solid #f1f1f1; */
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
    }
  }

  @media screen and (min-width: 768px) {
    .wrapper {
      padding: 10px;
    }
    .flip-card {
      background-color: transparent;
      height: 180px;
       /* width: 300px; */
      /*border: 1px solid #f1f1f1; */
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
    }
    #gameLogo {
      border-radius: 5px;
    }
    .inPageHeader {
      padding: 1.5rem
    }
    .inpageSection {
      padding: 2rem;
    }
      nav {
        padding: 10px;
      }
      .item-container {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border: 1px solid black;
        padding: 15px;
        margin: 5px 50px;
        border-radius: 10px;
      }
  }
.item-container {
  position: relative;
}
#gameHeader {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  /* padding: 0rem 1rem; */
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* #gameHeader > div:nth-child(1) {
  padding: 1rem;
  width: 90%;
} */
/* #gameHeader > div:nth-child(2) {
  text-align: right;
  padding: 0rem 2rem;
  width: 10%;
} */
  .file-upload-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
    border: 1px solid;
    border-radius: 5px;
 }
  .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 40px;
    line-height: 40px;
    color: #999;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
 }
  .file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 58px;
    background: #F8C937;
    color: #000;
    font-weight: 700;
    z-index: 25;
    font-size: 16px;
    line-height: 60px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 3px 3px 0;
 }
  .file-upload-wrapper:hover:before {
    background: #F8C937;
 }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
 }

 .MuiDialog-paperFullWidth {
  max-height: 80vh !important;
 }

 .list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
  /* padding: 10px 0px; */
}


.yellowBtn, .yellowBtn:hover {
background-color: #fabe44 !important;
color: black !important
}

.responsiveTable {
  border: 2px solid;
  border-radius: 1px;
  padding: 2px;
}

.error {
  font-size: 22px;
  color: red
}

.success {
  font-size: 22px;
  color: green
}

.center-table > tr > td {
  text-align: center;
}
.MuiDialog-root {
  z-index: 9999 !important;
}

#menu- {
  z-index: 999999999999 !important;
}

.center-table > tr, .responsiveTable > thead > tr {
  background-color: #f6cd65;
}

.responsiveTable > thead > tr  > th {
  padding: 10px
  }


.center-table > tr > td {
padding: 5px
}
.iconBox {
  display: block;
  text-align: center;
  background-color: rgb(232, 232, 232);
  padding: 20px;
  border-radius: 10px;
  margin: 5px;
  width: 30%;
}
.iconBox > h2 {
  margin: 0px
}


/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

#selectTeam {
  width: 100%;
  margin: 0px;
  border-radius: 5px;
  padding: 8px;
}

.flip-card-inner > div > div {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-inner-disabled:hover {
  cursor: not-allowed
}
.crossed {
  background:
      linear-gradient(to top left,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 0.8px),
          rgba(0,0,0,1) 50%,
          rgba(0,0,0,0) calc(50% + 0.8px),
          rgba(0,0,0,0) 100%),
      linear-gradient(to top right,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 0.8px),
          rgba(0,0,0,1) 50%,
          rgba(0,0,0,0) calc(50% + 0.8px),
          rgba(0,0,0,0) 100%);
}
/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}


/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.css-imwso6-MuiBottomNavigationAction-label {
  font-size: 14px !important;
}

.gameHome {
  text-align: center;
}

.styledBox {
    display: inline-flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: #000;
    border-radius: 10px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    align-items: center;
}

#taskDiv {
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  width: 80%;
  box-shadow: #000;
  border-radius: 20px;
  margin: 10px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  align-items: center;
}

#scoreDiv {
  display: inline-flex;
  justify-content: space-between;
  padding: 5px 30px;
  width: 80%;
  box-shadow: #000;
  border-radius: 10px;
  margin: 6px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
}

#media-upload {
  display: none;
}
.custom-file-upload {
  border: 2px solid;
  display: inline-block;
  padding: 11.5px;
  cursor: pointer;
}

.thumbnailHolder {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  border-radius: 10px;
  background-position: center !important;
  border: 2px solid;
}

.submitted {
  filter: opacity(0.5);
}
.submitted:hover {
  cursor: none;
}

.progress-container {
  background-color: rgb(192, 192, 192);
  width: 75%;
  border-radius: 15px;
  height: 20px;
}

.progressBar {
  height: 20px;
  background-color: rgb(116, 194, 92);
  color: white;
  padding: 1%;
  text-align: right;
  font-size: 20px;
  border-radius: 15px 5px 5px 15px;
}

.alert-message{
  line-height:30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* left: 4%;
  width: 90%;
  border-radius: 5px; */
  padding: 12px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, .9);
  box-shadow: 0px 10px 50px rgba(0,0,0,.6);
  transition: margin-top 0.4s ease-in-out;
}


.notification{
  /* margin-top: -10px; */
	height: 60px;
	margin: 0 auto;
	box-shadow: #95a5a6 0px 0px 6px 2px;
	color: white;
	line-height: 40px;
	overflow: hidden;
	animation: reveal 1 2s;
}
/* .notification .title{
	margin-right: 15px;
	padding: 0px 15px;
	line-height: 40px;
	display: inline-block;
}

.notification .close{
	background: rgba(255,255,255,0.2);
	padding: 0px 15px;
	float: right;
	line-height: 40px;
	display: inline-block;
	color: white;
}
.notification .close:hover{
  cursor: pointer;
} */
.notification.closed{
	transform: translate(0px, -50px);
	transition: 0.7s;
}

@keyframes reveal{
	0%{
		transform: translate(0px, -70px);
	}
	50%{
		transform: translate(0px, -70px);
	}
	100%{
		transform: translate(0px, 0px);
	}
}
.star {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.muirtl-sgoict-MuiCardHeader-action {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}
.inline-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#bronzeRank {
background: url("./assets/icons/bronzeRank.png");
background-size: cover;
}

#silverRank {
  background: url("./assets/icons/silverRank.png");
  background-size: cover;
}

#goldRank {
  background: url("./assets/icons/goldRank.png");
  background-size: cover;
}

main {
  overflow-y: scroll !important;
    height: 100vh;
}

.iconBox, #taskDiv, #scoreDiv, .shadowDiv {
  box-shadow: rgba(19, 19, 19, 0.548) 0px 20px 30px -10px;
}
.alertDiv {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
  border-radius: 10px;
  margin: 5px 0px;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
    0% { opacity: 0; }
  100% { opacity: 1; }
}


.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}


.delay-1 {
  animation-delay: 0.1s;
}

.delay-2 {
  animation-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.3s;
}

.delay-4 {
  animation-delay: 0.4s;
}

.delay-5 {
  animation-delay: 0.5s;
}

.delay-6 {
  animation-delay: 0.6s;
}

.delay-7 {
  animation-delay: 0.7s;
  }

  .delay-8 {
  animation-delay: 0.8s;
  }

  .delay-9 {
  animation-delay: 0.9s;
  }

  .delay-10 {
  animation-delay: 1.0s;
  }

  .delay-11 {
  animation-delay: 1.2s;
  }

  .delay-12 {
  animation-delay: 1.3s;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    width: 100%;
    padding: 0px 20px;
  }
  .pages {
    overflow-x: scroll;
  }
  .pagination button {
    margin: 0 2px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .pagination button:hover:not(:disabled) {
    background-color: #f0f0f0;
  }

  .pagination button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }

  .not-found-text{
    font-weight: bolder;
    text-align: center;
  }

  .add-task-container {
    display: flex;
    align-items: center;
  }

  .add-option-button {
    margin-top: 5px;
  }

  .add-task-container .add-task-field {
    width: calc(100% - 45px);
  }

  .remove-option-button {
    background-color: red;
    border-radius: 50px;
    padding: 5px;
    text-align: center;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .locked{
    filter: opacity(0.5);
    pointer-events: none;
    cursor: not-allowed;
}
